@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Lekton&family=Righteous&family=Roboto+Slab:wght@300&family=Roboto:wght@300;500;700&display=swap');

html,body {
  min-height: 100%;
  font-family: 'Didact Gothic', sans-serif;
  font-family: 'Lekton', sans-serif;
  font-family: 'Righteous', cursive;
  font-family: 'Roboto', sans-serif;
  font-family: 'Roboto Slab', serif;
}

#root {
  min-height: 100%;
}
